// Import React and other necessary modules
import React, { useState } from "react";
import "../assets/css/FullScreanProduct.css";
import { FaTimes } from "react-icons/fa"; // Import the closing icon

// Import images  "Dominomen"
import image1 from "../assets/images/products/Flockmen_012017_-_01c_1200x1200de45.jpg";
import image2 from "../assets/images/products/1.FlockmenSKINTONESset_smphoto.lv_1024x1024@2x1d7cc.jpg";
import image3 from "../assets/images/products/Flockmen_122016_-_05_1200x1200dcee.jpg";
import image4 from "../assets/images/products/Flockmen_122016_-_11c_ad92752c-e5b5-4222-b489-0a2aff2af8d5_1200x1200e460.jpg";

// Import images  "Dominomen"
import image6 from "../assets/images/products/starmen1.png";
import image7 from "../assets/images/products/starmen2.png";
import image8 from "../assets/images/products/starmen3.png";
import image9 from "../assets/images/products/starmen4.png";
import image10 from "../assets/images/products/starmen5.png";


// Define the FullScreen component
const FullScreanProduct = ({ productName }) => {
  // State variables
  const [fullscreen, setFullscreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const productImages =
  productName === "Dominomen"
    ? [image1, image2, image3,image4]
    : [image6,image7,image8,image9,image10];



  // Function to toggle fullscreen mode
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  // Function to switch to a specific image by index
  const switchImage = (index) => {
    setCurrentImageIndex(index);
  };

  // JSX structure
  return (
    <div>
      {/* Display fullscreen view */}
      <div className={`image-container ${fullscreen ? "fullscreen" : ""}`}>
        <img
          key={currentImageIndex}
          src={productImages[currentImageIndex]}
          alt={`Product Image ${currentImageIndex + 1}`}
          onClick={toggleFullscreen}
        />
        {/* Display navigation buttons only in fullscreen mode */}
        {fullscreen && (
          <>
            <button
              onClick={toggleFullscreen}
              className="exit-fullscreen-button"
            >
              <FaTimes /> {/* Closing icon */}
            </button>

            <div className="thumbnail-bar bottom">
              {productImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${
                    currentImageIndex === index ? "active" : ""
                  }`}
                  onClick={() => switchImage(index)}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {/* Display thumbnail bar */}
      <div className="thumbnail-bar">
        {productImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            className={`thumbnail ${
              currentImageIndex === index ? "active" : ""
            }`}
            onClick={() => switchImage(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FullScreanProduct;

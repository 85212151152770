import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

import useCart from "../hooks/useCart";

const Card = () => {
  const cartData = JSON.parse(localStorage.getItem("productData"));
  const { cartTotal, cartProducts, handleRemoveProduct } = useCart();

  // Calculate the subtotal
  const subtotal = cartProducts.reduce((acc, product) => {
    return acc + product.salePrice * product.quantity;
  }, 0);

  return (
    <>
      <Header />
      <main
        class="main-content js-focus-hidden"
        id="MainContent"
        role="main"
        tabindex="-1"
      >
        <div id="shopify-section-cart-template" class="shopify-section">
          <div
            class="page-width"
            data-section-id="cart-template"
            data-section-type="cart-template"
            data-ajax-enabled="true"
          >
            <div
              // class="hide"
              data-cart-wrapper
            >
              <div class="cart-header">
                <h1 class="cart-header__title">Your cart</h1>
                <a href="/products" class="text-link text-link--accent">
                Continuer vos achats
                </a>
              </div>

              {cartData && cartData.length > 0 ? ( // Check if cart is not empty
                <form
                  action="/checkouts"
                  // method="post"
                  novalidate
                  class="cart"
                >
                  <table>
                    <thead class="cart__row cart__row--heading">
                      <th scope="col">Produit</th>
                      <th class="text-right" scope="col">
                        Prix
                      </th>
                      <th class="text-right " scope="col">
                        Total
                      </th>
                      <th class="text-right " scope="col"></th>
                    </thead>

                    <tbody data-cart-line-items>
                      {cartProducts.map((product, index) => (
                        <tr key={product.id}>
                          <td>{product.productName}</td>
                          <td className="text-right">{product.salePrice} x {product.quantity}</td>
                         
                          <td className="text-right ">
                            {product.salePrice * product.quantity} TND
                          </td>
                          <td className="text-right ">
                            <button
                              className="remove-pro"
                              onClick={() => handleRemoveProduct(index)}
                            >
                              <i className="ion-trash-b"></i>
                            </button>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div class="cart__footer">
                    <div class="grid">
                      <div class="grid__item text-right small--text-center">
                        <div class="hide" data-cart-discount-wrapper>
                          <div
                            class="order-discount-card-wrapper"
                            data-cart-discount
                          >
                            <span class="order-discount order-discount--title order-discount--cart">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                role="presentation"
                                class="icon icon-saletag"
                              >
                                <path
                                  d="M10 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0-3H7a1 1 0 0 0-.71.29l-6 6a1 1 0 0 0 0 1.42l4 4a1 1 0 0 0 1.42 0c.19-.2 5.8-5.81 6-6A1 1 0 0 0 12 5V2a2 2 0 0 0-2-2z"
                                  fill="#231F20"
                                />
                              </svg>
                              <span class="visually-hidden">Réduction:</span>
                              <span data-cart-discount-title></span>
                            </span>
                            <span class="order-discount order-discount--cart order-discount--cart-total">
                              -<span data-cart-discount-amount></span>
                            </span>
                          </div>
                        </div>

                        <div class="cart-subtotal">
                          <span class="cart-subtotal__title">Sous-total</span>
                          <span class="cart-subtotal__price" data-cart-subtotal>
                            <span class="hulkapps-cart-original-total">
                              {subtotal.toFixed(2)} TND
                            </span>
                          </span>
                        </div>
                        <div class="cart__shipping rte">
                        Taxes incluses et frais de livraison calculés lors du paiement
                        </div>
                        <div class="cart__buttons-container">
                          <div class="cart__submit-controls">
                            {/* <input
                            type="submit"
                            name="checkout"
                            class="cart__submit btn btn--small-wide"
                            value="Check out"
                          /> */}
                            <button
                              class="btn btn-brand"
                              // style={{ display: 'block', color: '#DDDD23' }}
                              style={{
                                backgroundColor: "#eeb644",
                                color: "white",
                              }}
                            >
                              Check out
                            </button>
                          </div>

                          <div
                            class="cart__error-message-wrapper hide"
                            role="alert"
                            data-cart-error-message-wrapper
                          >
                            <span class="visually-hidden">Error </span>
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              role="presentation"
                              class="icon icon-error"
                              viewBox="0 0 14 14"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <path d="M7 0a7 7 0 0 1 7 7 7 7 0 1 1-7-7z" />
                                <path
                                  class="icon-error__symbol"
                                  d="M6.328 8.396l-.252-5.4h1.836l-.24 5.4H6.328zM6.04 10.16c0-.528.432-.972.96-.972s.972.444.972.972c0 .516-.444.96-.972.96a.97.97 0 0 1-.96-.96z"
                                  fill-rule="nonzero"
                                />
                              </g>
                            </svg>
                            <span
                              class="cart__error-message"
                              data-cart-error-message
                            ></span>
                          </div>
                          <div class="additional-checkout-buttons">
                            <div
                              class="dynamic-checkout__content"
                              id="dynamic-checkout-cart"
                              data-shopify="dynamic-checkout-cart"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                // Cart is empty
                <div
                  class="empty-page-content text-center"
                  data-empty-page-content
                >
                  {/* <h1>Your cart</h1> */}
                  <p class="cart--empty-message">
                  Votre panier est actuellement vide.
                  </p>
                  <div class="cookie-message">
                    <p>Activez les cookies pour utiliser le panier d'achat</p>
                  </div>
                  {/* <a
                href="/products"
                class="btn btn--has-icon-after cart__continue-btn"
              >
                Continue shopping
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  class="icon icon--wide icon-arrow-right"
                  viewBox="0 0 20 8"
                >
                  <path
                    d="M15.186.445c.865.944 1.614 1.662 2.246 2.154.631.491 1.227.857 1.787 1.098v.44a9.933 9.933 0 0 0-1.875 1.196c-.606.485-1.328 1.196-2.168 2.134h-.752c.612-1.309 1.253-2.315 1.924-3.018H.77v-.986h15.577c-.495-.632-.84-1.1-1.035-1.406-.196-.306-.486-.843-.87-1.612h.743z"
                    fill="#000"
                    fill-rule="evenodd"
                  />
                </svg>
              </a> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <style>
        {`
          .site-footer__hr {
            border-bottom: 1px solid #4e646c;
          }

          .site-footer p,
          .site-footer__linklist-item a,
          .site-footer__rte a,
          .site-footer h4,
          .site-footer small,
          .site-footer__copyright-content a,
          .site-footer__newsletter-error {
            color: #f6fbfc;
          }

          .site-footer {
            color: #f6fbfc;
            background-color: #647f89;
          }

          .site-footer__rte a {
            border-bottom: 1px solid #f6fbfc;
          }

          .site-footer__rte a:hover,
          .site-footer__linklist-item a:hover,
          .site-footer__copyright-content a:hover {
            color: #d0eaef;
            border-bottom: 1px solid #d0eaef;
          }
        `}
      </style>

      <Footer />
    </>
  );
};

export default Card;

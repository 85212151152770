import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductDetails from "../components/ProductDetails";
// import ProductDetails from "../components/FullScreanProduct";

const ProductsDetails = () => {
  // const imagesUrl = [
  //   require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg"),
  //   require("../assets/images/products/flockmen_8.09_l.hercenbergs-17_300x300992f.jpg"),
  //   require("../assets/images/products/28S_110x110@2x536f.jpg"),
  //   require("../assets/images/products/balons1_110x110@2x72c9.png")
  // ];

  // const imagesUrl2 = [
  //   require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg"),
  //   require("../assets/images/products/1S_530x@2x2bb5.jpg"),
  //   require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg"),
  //   require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg")
  // ];

  return (
    <>
      <Header />

      <ProductDetails
        productName="Flockmen 2"
        price="61,95"
        productDescription="Colours make our world beautiful! More alive, more fun! And no doubt that toys are a very important tool for learning things by providing basic development. You’ll receive 27 carefully crafted and 100% natural Flockmen figurines in 9 different colours (red, purple, orange, yellow, pink, gray, green, dark blue and light blue). Now construction will be even more fun! And learning, color recognition more accessible. Ask your child to put the red piece on top of the blue piece. Play some stacking games, by asking them to repeat your shape and colors. This is going to help develop an understanding of colours. Make a rainbow. Relate Flockmen colours with things in nature. Let's find your way of playing!"
        // imageUrl={require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg")}
        // imagesUrl={imagesUrl}
        // imagesUrl2={imagesUrl2}
      />

      <Footer />
    </>
  );
};

export default ProductsDetails;

import Footer from "../src/components/Footer";
import Header from "./components/Header";
import ProductDetails from "./containers/ProductsDetails";
import Products from "./containers/Products";
import Card from "./containers/Card";
import Checkouts from "./containers/Checkouts";

import Home from "./containers/Home";
import About from './containers/About';
import Gallery from './containers/Gallery';
import ProductDetailsPage from './containers/ProductDetailsPage';
import FullScreanProduct from './components/FullScreanProduct';

import { BrowserRouter, Routes, Route } from "react-router-dom";



function App() {

  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/details/:id" element={<ProductDetailsPage />} />
        {/* <Route path="/ProductDetailsPage" element={<ProductDetailsPage />} /> */}
        <Route path="/products/detailssss/:id" element={<ProductDetails />} />
        <Route path="/card" element={<Card />} />
        <Route path="/checkouts" element={<Checkouts />} />

        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About/>} />
        <Route path="/shop" element={<Products />} />
        <Route path="/blog" element={<Products />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/index" element={<Products />} />
        <Route path="/contact" element={<Products />} />
        <Route path="/fs" element={<FullScreanProduct />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;

import React, { useState } from "react";
import useCart from "../hooks/useCart";
import { useLocation } from "react-router-dom";

// import './Header.css'; // Import your custom CSS file for the off-canvas menu
import "../assets/css/theme.scss06c2.css";

const Header = () => {
  const { cartTotal, cartProducts, handleRemoveProduct } = useCart();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isNavLinkActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <header className="header-area">
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css"
        rel="stylesheet"
      />
      <div className="container container-wide">
        <div className="row align-items-center">
          <div className="col-sm-4 col-lg-2">
            <div className="site-logo text-center text-sm-left">
              <a href="/">
                <img src={require("../assets/images/logo2.png")} alt="Logo" />
              </a>
            </div>
          </div>
          <div className="col-lg-7 d-none d-lg-block">
            <div className="site-navigation">
              <ul className="main-menu nav">
                <li className={`menu-item ${isNavLinkActive("/")}`}>
                  <a href="/">Home</a>
                  {isNavLinkActive("/") && <hr />}
                </li>
                <li className={`menu-item ${isNavLinkActive("/about")}`}>
                  <a href="/about">About</a>
                  {isNavLinkActive("/about") && <hr />}
                </li>
                <li className={`menu-item ${isNavLinkActive("/shop")}`}>
                  <a href="/shop">Shop</a>
                  {isNavLinkActive("/shop") && <hr />}
                </li>
                <li className={`menu-item ${isNavLinkActive("/gallery")}`}>
                  <a href="/gallery">Gallery</a>
                  {isNavLinkActive("/gallery") && <hr />}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-8 col-lg-3">
            <div className="site-action d-flex justify-content-center justify-content-sm-end align-items-center">
              <div className="mini-cart-wrap" style={{ color: "#546a72" }}>
                <a href="/card" className="btn-mini-cart">
                  <i className="ion-bag"></i>{" "}
                  <span className="cart-total">{cartTotal}</span>
                </a>
                <div className="mini-cart-content">
                  {cartProducts.length > 0 ? (
                    <div className="mini-cart-product">
                      {cartProducts.map((product, index) => (
                        <div className="mini-product" key={index}>
                          <div className="mini-product__thumb">
                            <a href={`/products/details/${product.id}`}>
                              <img
                                src={require(product.productName === "Dominomen"
                                  ? "../../src/assets/images/products/Flockmen_012017_-_01c_1200x1200de45.jpg"
                                  : "../../src/assets/images/products/starmen1.png")}
                                alt="product"
                              />
                            </a>
                          </div>
                          <div className="mini-product__info">
                            <h2 className="title">
                              <a href={`/products/details/${product.id}`}>
                                {product.productName}
                              </a>
                            </h2>
                            <div className="mini-calculation">
                              <p className="price">
                                {product.quantity} x{" "}
                                <span> {product.salePrice} TND</span>
                              </p>
                              <button
                                className="remove-pro"
                                onClick={() => handleRemoveProduct(index)}
                              >
                                <i className="ion-trash-b"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No products in the cart</p>
                  )}
                </div>
              </div>
              <div className="responsive-menu d-lg-none">
                <button className="btn-menu" onClick={toggleMobileMenu}>
                  <i className="fa fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      {/* Off-canvas menu */}
      <div className={`off-canvas-wrapper ${isMobileMenuOpen ? "active" : ""}`}>
        <div className="off-canvas-inner">
          <div className="close-btn">
            <button className="btn-close" onClick={toggleMobileMenu}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="off-canvas-content">
            <ul className="slicknav_menu">
              {/* <br/> */}
              <li className="has-submenu">
                <a
                  href="/"
                  // style={{ color: "#546a72" }}
                >
                  Home
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="/about"
                  // style={{ color: "#546a72" }}
                >
                  About
                </a>
              </li>
              <hr />
              <li className="has-submenu">
                <a
                  href="/shop"
                  // style={{ color: "#546a72" }}
                >
                  Shop
                </a>
              </li>
              <hr />
              <li>
                <a
                  href="/gallery"
                  // style={{ color: "#546a72" }}
                >
                  Gallery
                </a>
              </li>
              <hr />
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <Header />
      <div
        class="page-header-wrap bg-img"
        // data-bg="../assets2/img/bg/page-header-bg.jpg"
        style={{
          backgroundImage: `url(${require("../assets/images/products/flockmen-cover-play-ideas-3.jpg")})`,
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <div class="page-header-content">
                <div class="page-header-content-inner">
                  <h1>About</h1>
                  {/* <ul class="breadcrumb">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li class="current">
                      <a href="#">About</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--== End Page Header Area ==--> */}
      {/* <!--== Start Page Content Wrapper ==--> */}
      <div class="page-content-wrapper sm-top">
        <div class="about-page-content">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 order-1 order-md-0">
                <div class="about-content">
                  <h2 class="h3 mb-sm-20">L'Enchantement du Jeu en Bois</h2>
                  <p>
                  Découvrez la joie infinie des petites filles qui s'immergent dans le bonheur du jeu en bois. Chez nous, chaque rire d'enfant devient une note enchantée, créant une symphonie de bonheur. Chacun de nos jeux est méticuleusement conçu pour éveiller l'imagination, transformer le quotidien en une aventure magique, et tisser des souvenirs qui dureront toute une vie.</p>
                  <p>
                  Plongez dans un monde féerique où chaque petit rire résonne avec la magie du jeu en bois. Nos jeux ne sont pas simplement des objets, mais des portails vers des aventures imaginaires. Chaque pièce est une histoire qui se déroule entre les mains curieuses des enfants, les inspirant à créer, explorer et rêver. Chez nous, chaque moment de jeu devient une célébration de l'enfance, où l'émerveillement est la clé de chaque nouvelle découverte.</p>

                </div>
              </div>
              
              <div class="col-lg-6 order-0">
                <div class="about-thumb mb-sm-30">
                  <img
                    src={require("../assets/images/products/Frame_10.jpg")}
                    alt="About"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row align-items-center sm-top">
              <div class="col-lg-6">
                <div class="about-thumb video-play mb-sm-30">
                  <img
                    src={require("../assets/images/products/Frame_12.jpg")}
                    alt="About"
                  />
                </div>
              </div>
              
              <div class="col-lg-6">
                <div class="about-content">
                  <h2 class="h3">Notre Mission</h2>
                  <p>
                  Chez nous, la mission est claire : inspirer et éveiller l'imagination à travers des jeux en bois uniques. Chacun de nos produits est conçu avec amour pour encourager la créativité, stimuler le développement cognitif, et créer des moments de bonheur partagés. Rejoignez-nous dans cette aventure où le jeu devient bien plus qu'une activité ludique ; c'est une source inépuisable d'apprentissage et de joie.
                  </p>
                  <p>
                  Notre engagement envers la durabilité va au-delà des jeux en bois. Nous œuvrons constamment à minimiser notre impact sur l'environnement, contribuant ainsi à un avenir plus durable. Choisir nos jeux, c'est choisir une entreprise dédiée à la création d'un monde meilleur, où chaque jeu en bois raconte une histoire de responsabilité et d'espoir pour les générations futures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--== End Page Content Wrapper ==--> */}

      {/* <!--== Start Call to action Wrapper ==--> */}
      <div class="call-to-action-area sm-top">
        <div
          class="call-to-action-content-area home-2 bg-img"
          //   data-bg={require("../assets2/img/bg/bg-1.jpg")}
          style={{
            backgroundImage: `url(${require("../assets2/img/bg/bg-1.jpg")})`,
          }}
        >
          <div class="container">
            <div class="row">
              <div class="col-12 ">
                <div class="call-to-action-txt">
                  <h2>
                  L'Enchantement 
                    <br />
                    des Pièces Essentielles
                  </h2>
                  <a href="/shop">
                  <button class="btn btn-brand" 
                    // style={{ display: 'block', color: '#DDDD23' }}
                    style={{ backgroundColor: '#eeb644', color: 'white' }}
                  >
                    Découvrir la Boutique
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="call-to-action-image-area">
          <div class="container">
            <div class="row">
              <div class="col-12 text-center">
                <img src={require("../assets/images/products/bg-wholesale - Copy.jpg")} alt="Car" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--== End Call to action Wrapper ==--> */}

      <Footer />
    </>
  );
};

export default About;

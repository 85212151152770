import React from "react";
import ProductItem from "../components/ProductItem";
import Header from "../components/Header";
import Footer from "../components/Footer";
import productsData from "../Data/products.json";



const Products = () => {
  return (
    <>
      <Header />

      <div id="shopify-section-collection-template" className="shopify-section">
        <div
          data-section-id="collection-template"
          data-section-type="collection-template"
        >
          <header className="collection-header">
            <div className="page-width">
              <div className="section-header text-center">
                <h1>
                  <span role="text">
                    <span className="visually-hidden">Collection: </span>
                    Products
                  </span>
                </h1>
                <span className="filters-toolbar__product-count">
                {productsData.length} Products
                </span>
              </div>
            </div>
          </header>

          <div className="page-width" id="Collection">
            <ul className="grid grid--uniform grid--view-items">
              <div>
                {productsData.map((product, index) => (
                  
                  <ProductItem
                    key={index} // Make sure to use a unique key for each ProductItem
                    productName={product.productName}
                    productLink={`/products/details/${product.id}`}
                    // productLink={`/products/detailssss/${product.id}`}
                    // imageUrl={require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg")}
                    imageUrl={
                      product.productName === 'Dominomen'
                        ? require('../assets/images/products/Flockmen_012017_-_01c_1200x1200de45.jpg')
                        : require('../assets/images/products/starmen1.png')
                    }
                    // imageUrl={require(product.imageUrl)}
                    // imageUrl={product.imageUrl}
                    // src={require(`${product.imageUrl}`)}
                    altText={product.altText}
                    regularPrice={product.regularPrice}
                    salePrice={product.salePrice}
                  />
                ))}
              </div>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;

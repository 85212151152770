import React, { useState, useRef } from "react";

import Style from "../assets/css/app.min.css";
import useCart from "../hooks/useCart";
import { MdError } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";

const Checkout = () => {
  const cartData = JSON.parse(localStorage.getItem("productData"));
  const { cartTotal, cartProducts, handleRemoveProduct } = useCart();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [telephone, setTelephone] = useState("");
  const [isTelephoneValid, setTelephoneValid] = useState(false);
  const [telephoneError, setTelephoneError] = useState("");
  // const [formValid, setFormValid] = useState(false);

  const form = useRef();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    localisation: "",
    telephone: "",
  });
  const [formErrors, setFormErrors] = useState({
    firstName: "*",
    lastName: "*",
    email: "*",
    localisation: "*",
    telephone: "*",
  });

  const [formValid, setFormValid] = useState(false);

  const validateForm = () => {
    const isFormValid = Object.values(formErrors).every((error) => {
      // console.log("Error:", error);
      return error === "";
    });
    // console.log("Is form valid?", isFormValid);
    setFormValid(isFormValid);
    return isFormValid;
  };

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case "firstName":
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          firstName:
            value.trim() !== ""
              ? /^[A-Za-z\s]+$/.test(value)
                ? ""
                : "Le prénom ne doit pas contenir de chiffres."
              : "Veuillez entrer votre prénom.",
        }));
        break;
      case "lastName":
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          lastName:
            value.trim() !== ""
              ? /^[A-Za-z\s]+$/.test(value)
                ? ""
                : "Le nom de famille ne doit pas contenir de chiffres."
              : "Veuillez entrer votre nom de famille.",
        }));
        break;
      case "email":
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? ""
            : "Veuillez entrer une adresse e-mail valide.",
        }));
        break;
      case "localisation":
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          localisation:
            value.trim() !== ""
              ? ""
              : "Veuillez entrer votre adresse complète.",
        }));
        break;
      case "telephone":
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          telephone: /^[0-9]{8}$/u.test(value)
            ? ""
            : "Veuillez entrer un numéro de téléphone valide.",
        }));
        break;
      default:
        break;
    }
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    validateField(fieldName, value);
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const result = await emailjs.sendForm(
        "service_p47ni99",
        "template_opgki66",
        form.current,
        "wEgzw-BmdM1R1Ojss"
      );

      // You may want to reset the form or navigate to another page here
    } catch (error) {
      // console.error(error.response);
      // Additional logic for form submission error
      console.log(
        "Le formulaire comporte des erreurs. Veuillez les corriger avant de soumettre."
      );

      // Open error toast
      toast.error(
        "Le formulaire comporte des erreurs. Veuillez les corriger avant de soumettre.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
        }
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (isFormValid) {
      sendEmail(e);
      console.log("Commande soumise avec succès!");

      // Open success toast
      toast.success("Commande soumise avec succès!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });

      // Clear local storage
      localStorage.removeItem("productData");

      // Wait for 5 seconds and then redirect to home page
      setTimeout(() => {
        // Redirect to home page
        window.location.href = "/";
      }, 3000); // 3000 milliseconds = 3 seconds

      // You may want to reset the form or navigate to another page here
    } else {
      console.log(
        "Le formulaire comporte des erreurs. Veuillez les corriger avant de soumettre."
      );

      // Open error toast
      toast.error(
        "Le formulaire comporte des erreurs. Veuillez les corriger avant de soumettre.",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        }
      );
    }
  };

  // Calculate the subtotal
  const subtotal = cartProducts.reduce((acc, product) => {
    return acc + product.salePrice * product.quantity;
  }, 0);

  const validateTelephone = (e) => {
    e.preventDefault();
    const isTelephoneValid = /^[0-9]{10}$/u.test(telephone);
    setTelephoneValid(isTelephoneValid);

    if (!isTelephoneValid) {
      setTelephoneError("Veuillez entrer un numéro de téléphone valide.");
    } else {
      setTelephoneError("");
    }

    validateForm();
  };

  // *****************************

  return (
    <div className="page-content-wrapper">
      <div className="cart-page-content-wrap">
        <div className="container container-wide">
          <div className="row">
            <div className="col-12">
              <div className="checkout-page-coupon-area"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {/* <!-- Checkout Form Area Start --> */}
              <div className="checkout-billing-details-wrap">
                <h2>Détails de Facturation</h2>
                <div className="billing-form-wrap">
                  <form action="#" ref={form} method="post">
                    <div className="row">
                      {/* Prénom */}
                      <div className="col-md-6">
                        <div className="input-item mt-0">
                          <label for="f_name" className="sr-only required">
                            Prénom
                          </label>
                          {" Prénom "}
                          <input
                            type="text"
                            id="f_name"
                            placeholder="Prénom"
                            required
                            defaultValue={formData.firstName}
                            name="firstName"
                            onChange={(e) =>
                              handleInputChange("firstName", e.target.value)
                            }
                            onBlur={() =>
                              validateField("firstName", formData.firstName)
                            }
                          />
                          {formErrors.firstName && (
                            <InputError message={formErrors.firstName} />
                          )}
                        </div>
                      </div>

                      {/* Nom */}
                      <div className="col-md-6">
                        <div className="input-item mt-md-0">
                          <label for="l_name" className="sr-only">
                            Nom de Famille
                          </label>
                          {"Nom de Famille "}
                          <input
                            type="text"
                            id="l_name"
                            name="lastName"
                            placeholder="Nom de Famille                                "
                            required
                            defaultValue={formData.lastName}
                            onChange={(e) =>
                              handleInputChange("lastName", e.target.value)
                            }
                            onBlur={() =>
                              validateField("lastName", formData.lastName)
                            }
                          />
                          {formErrors.lastName && (
                            <InputError message={formErrors.lastName} />
                          )}
                        </div>
                      </div>
                    </div>

                    {/* email */}
                    <div className="input-item">
                      <label for="email" className="sr-only">
                        Adresse E-mail
                      </label>
                      {" Adresse E-mail "}
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Adresse E-mail"
                        required
                        defaultValue={formData.email}
                        onChange={(e) =>
                          handleInputChange("email", e.target.value)
                        }
                        onBlur={() => validateField("email", formData.email)}
                      />
                      {formErrors.email && (
                        <InputError message={formErrors.email} />
                      )}
                    </div>

                    {/* Localisation */}
                    <div className="input-item">
                      <label for="street-address" className="sr-only">
                        Adresse Complète
                      </label>
                      {" Adresse Complète "}
                      <input
                        type="text"
                        id="street-address"
                        name="localisation"
                        placeholder="Entrez l'adresse complète"
                        required
                        defaultValue={formData.localisation}
                        onChange={(e) =>
                          handleInputChange("localisation", e.target.value)
                        }
                        onBlur={() =>
                          validateField("localisation", formData.localisation)
                        }
                      />
                      {formErrors.localisation && (
                        <InputError message={formErrors.localisation} />
                      )}
                    </div>

                    {/* Téléphone */}
                    <div className="input-item">
                      <label htmlFor="phone" className="sr-only">
                        Téléphone
                      </label>
                      {" Téléphone "}
                      <input
                        type="text"
                        id="phone"
                        name="telephone"
                        placeholder="Téléphone"
                        defaultValue={formData.telephone}
                        onChange={(e) =>
                          handleInputChange("telephone", e.target.value)
                        }
                        onBlur={() =>
                          validateField("telephone", formData.telephone)
                        }
                      />
                      {formErrors.telephone && (
                        <InputError message={formErrors.telephone} />
                      )}
                    </div>

                    {/* Note de Commande */}
                    <div className="input-item">
                      <label for="ordernote" className="sr-only">
                        Note de Commande
                      </label>
                      {"Note de Commande"}
                      <textarea
                        name="ordernote"
                        id="ordernote"
                        cols="30"
                        rows="3"
                        placeholder="Notes sur votre commande, par exemple des instructions spéciales pour la livraison.                            "
                      ></textarea>
                    </div>
                    {/* Autres Details  */}
                    <div className="input-item">
                      <textarea
                        style={{ display: "none" }}
                        name="TotalCommande"
                        id="TotalCommande"
                        value={
                          cartProducts.map(
                            (product, index) =>
                              "\n" +
                              product.productName +
                              " * " +
                              product.quantity +
                              " : " +
                              product.salePrice * product.quantity +
                              " DT "
                          ) +
                          "\n\n Total : " +
                          (subtotal + 7).toFixed(2) +
                          " DT"
                        }
                        // value={}
                      ></textarea>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-xl-5 ml-auto">
              {/* <!-- Checkout Page Order Details --> */}
              <div className="order-details-area-wrap">
                <h2>Votre Commande</h2>
                <div className="order-details-table table-responsive">
                  <table className="table table-borderless">
                    <thead>
                      <tr>
                        <th>Produits</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartProducts.map((product, index) => (
                        <tr className="cart-item" key={index}>
                          <td>
                            <span className="product-title">
                              {product.productName}
                            </span>{" "}
                            <span className="product-quantity">
                              &#215; {product.quantity}
                            </span>
                          </td>
                          <td>{product.salePrice * product.quantity} TND</td>
                        </tr>
                      ))}
                    </tbody>

                    <tfoot>
                      <tr className="cart-subtotal">
                        <th>Sous-total</th>
                        <td>{subtotal.toFixed(2)} TND</td>
                      </tr>
                      <tr className="shipping">
                        <th>Livraison</th>
                        <td>
                          <ul className="shipping-method">
                            <li>
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  id="flat_shipping"
                                  name="shipping_method"
                                  className="custom-control-input"
                                  checked="checked"
                                />
                                <label
                                  className="custom-control-label"
                                  for="flat_shipping"
                                >
                                  frais de livraison: 7 TND
                                </label>
                              </div>
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr className="final-total">
                        <th>Total</th>
                        <td>
                          <span className="total-amount">
                            {(subtotal + 7).toFixed(2)} TND
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>

                <div className="order-details-footer">
                  <p>
                    Vos données personnelles seront utilisées pour traiter votre
                    commande, soutenir votre expérience tout au long de ce site
                    web et à d'autres fins décrites dans notre{" "}
                    {/* <a href="#" className="text-warning"> */}
                    <a className="text-warning">Politique de Confidentialité</a>
                    .
                  </p>
                  <div className="custom-control custom-checkbox mt-10">
                    <input
                      type="checkbox"
                      id="privacy"
                      className="custom-control-input"
                      required
                    />{" "}
                    <label for="privacy" className="custom-control-label">
                      J'ai lu et j'accepte les termes et conditions du site web.
                    </label>
                  </div>
                  {/* <button className="btn btn-bordered mt-40">
                        Place Order
                      </button> */}
                  <br></br>
                  <button
                    className="btn btn-brand"
                    style={{
                      backgroundColor: "#eeb644",
                      color: "white",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      Object.keys(formData).forEach((field) =>
                        validateField(field, formData[field])
                      );

                      if (validateForm()) {
                        handleSubmit(e);
                      }
                    }}
                  >
                    Passer la Commande
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Toast Container for Notifications */}
      <ToastContainer />
    </div>
  );
};

const InputError = ({ message }) => {
  return (
    <p className="error-message" style={{ color: "red" }}>
      <MdError />
      {message}
    </p>
  );
};

export default Checkout;

// useProductStorage.js

import { useState, useEffect } from "react";

export function useProductStorage() {
  const [productData, setProductData] = useState([]);
  const storageKey = "productData";

  useEffect(() => {
    const storedData = localStorage.getItem(storageKey);

    if (storedData) {
      setProductData(JSON.parse(storedData));
    } else {
      const initialData = [];
      setProductData(initialData);
      localStorage.setItem(storageKey, JSON.stringify(initialData));
    }
  }, []);

  const addProduct = (newProduct, quantity) => {
    const productIndex = productData.findIndex(
      (product) => product.id === newProduct.id
    );

    // Check if quantity is valid (not NaN) and greater than 0
    if (isNaN(quantity) || quantity <= 0) {
      // If quantity is not valid, default to 1
      quantity = 1;
    }

    if (productIndex !== -1) {
      // If the product already exists, update its quantity
      const updatedProductData = [...productData];
      updatedProductData[productIndex].quantity += quantity;
      setProductData(updatedProductData);
      localStorage.setItem(storageKey, JSON.stringify(updatedProductData));
    } else {
      // If the product doesn't exist, add it to the data
      const productWithQuantity = {
        ...newProduct,
        quantity: quantity,
      };
      const updatedProductData = [...productData, productWithQuantity];
      setProductData(updatedProductData);
      localStorage.setItem(storageKey, JSON.stringify(updatedProductData));
    }
  };

  return { productData, addProduct };
}

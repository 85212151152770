import React from "react";

import Header from "../components/Header"
import Footer from "../components/Footer"
import Checkout from "../components/Checkout"

const Checkouts = () => {
  return (
   <>
        <Header />
        <Checkout />        
        <Footer />

   </>
  );
};

export default Checkouts;
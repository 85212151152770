import { useState, useEffect } from 'react';

function useCart() {
  // Initialize a state variable to store the cart total
  const [cartTotal, setCartTotal] = useState(0);
  const [cartProducts, setCartProducts] = useState([]);

  useEffect(() => {
    // Retrieve the productData from local storage
    const storedData = localStorage.getItem('productData');
    if (storedData) {
      const productData = JSON.parse(storedData);
      // Get the length of the productData to determine the cart total
      const totalItems = productData.length;
      setCartTotal(totalItems);
      setCartProducts(productData);
    }
  }, []);

  const handleRemoveProduct = (index) => {
    // Create a copy of the cartProducts array
    const updatedCartProducts = [...cartProducts];
    // Remove the product at the specified index
    updatedCartProducts.splice(index, 1);
    // Update the state with the new cartProducts array
    setCartProducts(updatedCartProducts);
    // Update the local storage with the updated cartProducts
    localStorage.setItem('productData', JSON.stringify(updatedCartProducts));

    window.location.reload();

  };

  return {
    cartTotal,
    cartProducts,
    handleRemoveProduct,
  };
}

export default useCart;
import React, { useEffect, useState } from "react";

// External Libraries
import "@fortawesome/fontawesome-free/css/all.min.css"; //npm install --save @fortawesome/fontawesome-free

// Global Styles
import "../InterfaceFrontAssets/css/aos.css";
import "../InterfaceFrontAssets/css/lightcase.css";
import "../InterfaceFrontAssets/css/swiper-bundle.min.css";
import "../InterfaceFrontAssets/css/all.min.css"; // It's included in global styles because it may contain styles that apply to the entire application.

// Component-Specific Styles (if any)
import "../InterfaceFrontAssets/css/bootstrap.min.css";
import "../InterfaceFrontAssets/css/style.css";

import googleIcon from "../InterfaceFrontAssets/images/others/google.svg";

import AOS from "aos"; //to work with animation support

import Header from "../components/Header";
import Footer from "../components/Footer";

const Home = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  });
  return (
    <>
      <Header />

      {/* =============== Banner section start here =================  */}
      <section
        className="banner padding-bottom  bg--cover "
        style={{
          backgroundImage: `url(${require("../InterfaceFrontAssets/images/bg/home3/1.png")})`,
        }}
      >
        <div className="container py-5">
          <div className="banner__wrapper">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="banner__content banner__content--style2">
                  <div className="banner__content-star">
                    <img
                      src={require("../InterfaceFrontAssets/images/icon1.png")}
                      alt="star icon"
                    />
                    <div className="star-details">
                      <span>Découvrez et Apprenez en Jouant</span>
                    </div>
                  </div>
                  <h1>Le Héros de l'Aventure de Votre Enfant</h1>
                  <p>
                    “Laissez libre cours à l'imagination avec le jouet
                    polyvalent starmen.
                    <br />
                    Fabriqué en bois naturel et conçu pour inspirer le jeu
                    créatif.”
                  </p>
                  <div className="btn-group">
                    <a
                      href="/about"
                      className="trk-btn btn-brand trk-btn--secondary"
                      style={{
                        backgroundColor: "#ffffff",
                        color: "black",
                        // padding: "10px 20px",
                        // borderRadius: "5px",
                        border: "2px solid #eeb644", // Add a white border
                        // textDecoration: "none",
                        // display: "inline-block",
                      }}
                    >
                      Go to About
                    </a>

                    {/* <button
                        class="btn btn-brand"
                        // style={{ display: 'block', color: '#DDDD23' }}
                        style={{
                          backgroundColor: "#eeb644",
                          color: "white",
                        }}
                      ></button> */}
                    <a
                      href="/shop"
                      className="trk-btn  trk-btn--secondary3 btn-brand"
                      style={{
                        backgroundColor: "#eeb644",
                        color: "white",
                      }}
                    >
                      Commencer
                      <span>
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner__thumb banner__thumb--style3">
                  <div className="banner__thumb-item">
                    <img
                      src={require("../InterfaceFrontAssets/images/banner/5.png")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="banner__icon banner__icon--style2 padding-bottom">
          <span className="banner__icon-item banner__icon-item--animation">
            <img
              src={require("../InterfaceFrontAssets/images/banner4.png")}
              alt="dot icon"
            />
          </span>
          <span className="banner__icon-item banner__icon-item--animation">
            <img
              src={require("../InterfaceFrontAssets/images/banner3.png")}
              alt="star icon"
            />
          </span>
          <span className="banner__icon-item banner__icon-item--animation">
            <img
              src={require("../InterfaceFrontAssets/images/banner3.png")}
              alt="watch icon"
            />
          </span>
        </div>

        {/* Start  Categories Section */}
        <div class="categories__wrapper container padding-bottom">
          <div class="row g-4">
            <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12">
              <div
                class="categories__header"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div class="subtitle subtitle--style3">
                  {/* <img */}
                  {/* // src={require("../InterfaceFrontAssets/images/icon/home3/")} */}
                  {/* // src={require("../InterfaceFrontAssets/images/banner4.png")} */}
                  {/* //alt="star icon" */}
                  {/* ///>/ */}
                  <p class="mb-0">Shop Details</p>
                </div>
                <h2>EXPLOREZ NOS CATÉGORIES</h2>
                <p class="style3">
                  Découvrez l'univers des jeux en bois conçus pour éveiller la
                  joie et la créativité chez chaque enfant. Plongez vos petits
                  dans le royaume enchanteur des jouets artisanaux qui inspirent
                  l'imagination et l'apprentissage à travers le plaisir du jeu.
                </p>

                <a href="/gallery" class="trk-btn trk-btn--secondary3 btn-brand">
                  {/* <a
                      href="/"
                      className="trk-btn  trk-btn--secondary3 btn-brand"
                      style={{
                          backgroundColor: "#eeb644",
                          color: "white",
                        }}
                    > */}
                  Toutes les Catégories
                  <span>
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>{" "}
                </a>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-6">
              <div
                class="categories__item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div class="categories__item-inner">
                  <div class="categories__item-thumb">
                    <img
                      src={require("../InterfaceFrontAssets/images/category/home3/1.png")}
                      alt="course icon"
                    />
                  </div>
                  <div class="categories__item-content">
                    <a
                      href="/shop"
                      class="stretched-link active"
                    >
                      Jouets Écologiques
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-6">
              <div
                class="categories__item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div class="categories__item-inner">
                  <div class="categories__item-thumb">
                    <img
                      src={require("../InterfaceFrontAssets/images/category/home3/2.png")}
                      alt="course icon"
                    />
                  </div>
                  <div class="categories__item-content">
                    <a
                      href="/shop"
                      class="stretched-link active"
                    >
                      Puzzles en Bois
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-6">
              <div
                class="categories__item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <div class="categories__item-inner">
                  <div class="categories__item-thumb">
                    <img
                      src={require("../InterfaceFrontAssets/images/category/home3/3.png")}
                      alt="course icon"
                    />
                  </div>
                  <div class="categories__item-content">
                    <a
                      href="/shop"
                      class="stretched-link active"
                    >
                      Blocs de Construction Créatifs
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4  col-md-4 col-6">
              <div
                class="categories__item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="200"
              >
                <div class="categories__item-inner">
                  <div class="categories__item-thumb">
                    <img
                      src={require("../InterfaceFrontAssets/images/category/home3/4.png")}
                      alt="course icon"
                    />
                  </div>
                  <div class="categories__item-content">
                    <a
                      href="/shop"
                      class="stretched-link active"
                    >
                      Ensembles de Jeu Imaginatifs
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-6">
              <div
                class="categories__item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="300"
              >
                <div class="categories__item-inner">
                  <div class="categories__item-thumb">
                    <img
                      src={require("../InterfaceFrontAssets/images/category/home3/8.png")}
                      alt="course icon"
                    />
                  </div>
                  <div class="categories__item-content">
                    <a
                      href="/shop"
                      class="stretched-link active"
                    >
                      Jeux Éducatifs Colorés
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-4 col-6">
              <div
                class="categories__item"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="400"
              >
                <div class="categories__item-inner">
                  <div class="categories__item-thumb">
                    <img
                      src={require("../InterfaceFrontAssets/images/category/home3/7.png")}
                      alt="course icon"
                    />
                  </div>
                  <div class="categories__item-content">
                    <a
                      href="/shop"
                      class="stretched-link active"
                    >
                      Inspirés par la Nature
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============== Banner section end here =================  */}
      {/* =============== Banner section end here =================  */}

      <Footer />
    </>
  );
};

export default Home;

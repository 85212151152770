import React from "react";

const ProductItem = (props) => {
  const {
    productName,
    price,
    productLink,
    imageUrl,
    altText,
    regularPrice,
    salePrice,
  } = props;

  return (
    <li className="grid__item grid__item--collection-template small--one-half medium-up--one-third">
      <div className="grid-view-item product-card">
        <a
          className="grid-view-item__link grid-view-item__image-container full-width-link"
          href={productLink}
        >
          <span className="visually-hidden">{altText}</span>
        </a>

        <div
          className="product-card__image-with-placeholder-wrapper"
          data-image-with-placeholder-wrapper
        >
          <div className="grid-view-item__image-wrapper product-card__image-wrapper js">
            <div style={{ paddingTop: "100.0%" }}>
              <img
                className="grid-view-item__image"
                src={imageUrl}
                alt={altText}
                style={{ maxWidth: "345.0px" }}
                    />
            </div>
          </div>
          <div
            className="placeholder-background placeholder-background--animation"
            data-image-placeholder
          ></div>
        </div>
    
        <div
          className="h4 grid-view-item__title product-card__title"
          aria-hidden="true"
        >
          {productName}
        </div>
        <dl className="price" data-price>
          <div className="price__regular">
            <dt>
              <span className="visually-hidden visually-hidden--inline">
                Regular price
              </span>
            </dt>
            <dd>
              <span
                className="price-item price-item--regular"
                data-regular-price
              >
                {regularPrice}
              </span>
            </dd>
          </div>
          <div className="price__sale">
            <dt>
              <span className="visually-hidden visually-hidden--inline">
                Sale price
              </span>
            </dt>
            <dd>
              <span className="price-item price-item--sale" data-sale-price>
                {salePrice}
              </span>
              <span className="price-item__label" aria-hidden="true">
                Sale
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </li>
  );
};

export default ProductItem;

import React, { useEffect, useState } from "react";
import { redirect, useParams } from "react-router-dom";
import productsData from "../Data/products.json";
import { useProductStorage } from "../hooks/useLocalStorage";

import "@fortawesome/fontawesome-free/css/all.min.css"; //npm install --save @fortawesome/fontawesome-free



const ProductDetails = (props) => {
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { addProduct } = useProductStorage();

  //new Local Storage
  const initialProductData = [];
  // Initialize state to store the product data
  const [productData, setProduct] = useState(initialProductData);

  const productName = productData.productName;
  const price = productData.price;
  // const imageUrl = require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg");
  // const imagesUrl = productData.imagesUrl;
  const imagesUrl = [
      
    require("../assets/images/products/1S_530x@2x2bb5.jpg"),
    require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg"),
    require("../assets/images/products/1S_530x@2x2bb5.jpg"),
  ];
  const productDescription = productData.productDescription;

  useEffect(() => {
    const productById = productsData.find(
      (product) => product.id === parseInt(id)
    );

    if (productById) {
      setProduct(productById);
    }
  }, [id]);

  if (!productData) {
    return <div>Loading...</div>;
  }

  const handleAddToCartClick = () => {
    const quantityInput = document.getElementById("Quantity-product-template");
    const quantity = parseInt(quantityInput.value);

    // Pass the product and quantity to the addProduct function
    addProduct(productData, quantity);
  };

  const handleThumbnailClick = (event, index) => {
    event.preventDefault();
    setCurrentIndex(index);

    // Update the source of the displayed image based on the clicked thumbnail
    const featuredImage = document.getElementById(
      "FeaturedImage-product-template-13338689503326"
    );
    featuredImage.src = imagesUrl[index];
  };

  const handleNextClick = (event) => {
    event.preventDefault();
    // Increment the index and handle wrap-around if needed
    const nextIndex = (currentIndex + 1) % imagesUrl.length;
    setCurrentIndex(nextIndex);
  };

  const handlePrevClick = (event) => {
    event.preventDefault();
    // Decrement the index and handle wrap-around if needed
    const prevIndex = (currentIndex - 1 + imagesUrl.length) % imagesUrl.length;
    setCurrentIndex(prevIndex);
  };
  return (
    <div
      className="product-template__container page-width"
      id="ProductSection-product-template"
      data-section-id="product-template"
      data-section-type="product"
      data-enable-history-state="true"
      data-ajax-enabled="true"
    >
      {/* <!-- "snippets/preorder-now-collection-item.liquid" was not rendered, the associated app was uninstalled --> */}

      <div className="grid product-single product-single--medium-image">
        <div className="grid__item product-single__photos medium-up--one-half">
          {/* <style>
            {`
              #FeaturedImage-product-template-13338689470558 {
                max-width: 530.0px;
                max-height: 530px;
              }
              
              #FeaturedImageZoom-product-template-13338689470558-wrapper {
                max-width: 530.0px;
              }
            `}
          </style> */}

          <div
            id="FeaturedImageZoom-product-template-13338689470558-wrapper"
            className="product-single__photo-wrapper js"
          >
            <div
              id="FeaturedImageZoom-product-template-13338689470558"
              style={{ paddingTop: "100.0%" }}
              className="product-single__photo js-zoom-enabled product-single__photo--has-thumbnails"
              data-image-id="13338689470558"
              data-zoom="//flockmen.com/cdn/shop/products/1S_1024x1024@2x.jpg?v=1572338434"
            >
              <img
                id="FeaturedImage-product-template-13338689470558"
                className="feature-row__image product-featured-img lazyload"
                src={imagesUrl[0]}
                data-src={imagesUrl[0]}
                data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                data-aspectratio="1.0"
                data-sizes="auto"
                tabindex="-1"
                alt={productName}
              />
            </div>
          </div>

          {/* <style>
            {`
              #FeaturedImage-product-template-13338689503326 {
                max-width: 530.0px;
                max-height: 530px;
              }
              
              #FeaturedImageZoom-product-template-13338689503326-wrapper {
                max-width: 530.0px;
              }
            `}
          </style> */}

          <div
            id="FeaturedImageZoom-product-template-13338689503326-wrapper"
            className="product-single__photo-wrapper js"
          >
            <div
              id="FeaturedImageZoom-product-template-13338689503326"
              style={{ paddingTop: "100.0%" }}
              className="product-single__photo js-zoom-enabled product-single__photo--has-thumbnails hide"
              data-image-id="13338689503326"
              data-zoom="//flockmen.com/cdn/shop/products/8S_1024x1024@2x.jpg?v=1572338434"
            >
              <img
                id="FeaturedImage-product-template-13338689503326"
                className="feature-row__image product-featured-img lazyload lazypreload"
                // src="../cdn/shop/products/8S_300x3002bb5.jpg?v=1572338434"
                src={imagesUrl[0]}
                data-src="../cdn/shop/products/8S_%7bwidth%7dx2bb5.html?v=1572338434"
                data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                data-aspectratio="1.0"
                data-sizes="auto"
                tabindex="-1"
                alt={productName}
              />
            </div>
          </div>

          {/* <style>
            {`
                #FeaturedImage-product-template-13338689536094 {
                  max-width: 530.0px;
                  max-height: 530px;
                }

                #FeaturedImageZoom-product-template-13338689536094-wrapper {
                  max-width: 530.0px;
                }

                `}
          </style> */}

          <div
            id="FeaturedImageZoom-product-template-13338689536094-wrapper"
            className="product-single__photo-wrapper js"
          >
            <div
              id="FeaturedImageZoom-product-template-13338689536094"
              style={{ paddingTop: "100.0%" }}
              className="product-single__photo js-zoom-enabled product-single__photo--has-thumbnails hide"
              data-image-id="13338689536094"
              data-zoom="//flockmen.com/cdn/shop/products/2S_1024x1024@2x.jpg?v=1572338434"
            >
              <img
                id="FeaturedImage-product-template-13338689536094"
                className="feature-row__image product-featured-img lazyload lazypreload"
                src="../cdn/shop/products/2S_300x3002bb5.jpg?v=1572338434"
                data-src="../cdn/shop/products/2S_%7bwidth%7dx2bb5.html?v=1572338434"
                data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                data-aspectratio="1.0"
                data-sizes="auto"
                tabindex="-1"
                alt={productName}
              />
            </div>
          </div>

          {/* <style>
            {`
                #FeaturedImage-product-template-13399009263710 {
                  max-width: 530.0px;
                  max-height: 530px;
                }

                #FeaturedImageZoom-product-template-13399009263710-wrapper {
                  max-width: 530.0px;
                }
                `}
          </style> */}

          <div
            id="FeaturedImageZoom-product-template-13399009263710-wrapper"
            className="product-single__photo-wrapper js"
          >
            <div
              id="FeaturedImageZoom-product-template-13399009263710"
              style={{ paddingTop: "100.0%" }}
              className="product-single__photo js-zoom-enabled product-single__photo--has-thumbnails hide"
              data-image-id="13399009263710"
              data-zoom="//flockmen.com/cdn/shop/products/28S_1024x1024@2x.jpg?v=1573553230"
            >
              <img
                id="FeaturedImage-product-template-13399009263710"
                className="feature-row__image product-featured-img lazyload lazypreload"
                src="../cdn/shop/products/28S_300x300536f.jpg?v=1573553230"
                data-src="../cdn/shop/products/28S_%7bwidth%7dx536f.html?v=1573553230"
                data-widths="[180, 360, 540, 720, 900, 1080, 1296, 1512, 1728, 2048]"
                data-aspectratio="1.0"
                data-sizes="auto"
                tabindex="-1"
                alt={productName}
              />
            </div>
          </div>

          <noscript>
            <img
              src="../cdn/shop/products/1S_530x%402x2bb5.jpg?v=1572338434"
              alt={productName}
              id="FeaturedImage-product-template"
              className="product-featured-img"
              style={{ maxWidth: "530px" }}
            />
          </noscript>

          <div className="thumbnails-wrapper thumbnails-slider--active">
            <button
              type="button"
              className="btn btn--link medium-up--hide thumbnails-slider__btn thumbnails-slider__prev thumbnails-slider__prev--product-template"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-chevron-left"
                viewBox="0 0 7 11"
              >
                <path
                  d="M5.5.037a1.5 1.5 0 0 1 1.06 2.56l-2.94 2.94 2.94 2.94a1.5 1.5 0 0 1-2.12 2.12l-4-4a1.5 1.5 0 0 1 0-2.12l4-4A1.5 1.5 0 0 1 5.5.037z"
                  fill="#fff"
                  className="layer"
                />
              </svg>
              <span className="icon__fallback-text">Previous slide</span>
            </button>

            <ul className="grid grid--uniform product-single__thumbnails product-single__thumbnails-product-template">
              {imagesUrl.map((image, index) => (
                <li
                  key={index}
                  className="grid__item medium-up--one-quarter product-single__thumbnails-item js"
                >
                  <a
                    href={image}
                    className="text-link product-single__thumbnail product-single__thumbnail--product-template"
                    data-thumbnail-id={`thumbnail-${index}`}
                    data-zoom={image}
                    onClick={(event) => handleThumbnailClick(event, image)}
                  >
                    <img
                      className="product-single__thumbnail-image"
                      src={image}
                      alt={`Thumbnail ${index}`}
                    />
                  </a>
                </li>
              ))}
            </ul>

            {/* Add the "Next" button */}
            <button
              type="button"
              className="btn btn--link medium-up--hide thumbnails-slider__btn thumbnails-slider__next thumbnails-slider__next--product-template"
              onClick={handleNextClick}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-chevron-right"
                viewBox="0 0 7 11"
              >
                <path
                  d="M1.5 11A1.5 1.5 0 0 1 .44 8.44L3.38 5.5.44 2.56A1.5 1.5 0 0 1 2.56.44l4 4a1.5 1.5 0 0 1 0 2.12l-4 4A1.5 1.5 0 0 1 1.5 11z"
                  fill="#fff"
                />
              </svg>
              <span className="icon__fallback-text">Next slide</span>
            </button>
          </div>
        </div>

        <div className="grid__item medium-up--one-half">
          <div className="product-single__meta">
            <h1 className="product-single__title">{productName}</h1>
            <span
              className="stamped-product-reviews-badge stamped-main-badge"
              data-id="9812910802"
              data-product-title={productName}
              data-product-type=""
              style={{ display: "block;" }}
            ></span>

            <div className="product__price">
              {/* <!-- snippet/product-price.liquid --> */}

              <dl className="price" data-price>
                <div className="price__regular">
                  <dt>
                    <span className="visually-hidden visually-hidden--inline">
                      Regular price
                    </span>
                  </dt>
                  <dd>
                    <span
                      className="price-item price-item--regular"
                      data-regular-price
                    >
                      {productData.salePrice} TND
                    </span>
                  </dd>
                </div>
                <div className="price__sale">
                  <dt>
                    <span className="visually-hidden visually-hidden--inline">
                      Sale price
                    </span>
                  </dt>
                  <dd>
                    <span
                      className="price-item price-item--sale"
                      data-sale-price
                    >
                      {/* {productData.price} */}
                    </span>
                    <span className="price-item__label" aria-hidden="true">
                      Sale
                    </span>
                  </dd>
                </div>
                <div className="price__unit">
                  <dt>
                    <span className="visually-hidden visually-hidden--inline">
                      Unit price
                    </span>
                  </dt>
                  <dd className="price-unit-price">
                    <span data-unit-price></span>
                    <span aria-hidden="true">/</span>
                    <span className="visually-hidden">per&nbsp;</span>
                    <span data-unit-price-base-unit></span>
                  </dd>
                </div>
              </dl>
            </div>
            <div className="product__policies rte">Tax included.</div>

            <form
              // method="post"
              // action="https://flockmen.com/cart/add"
              id="product_form_9812910802"
              accept-charset="UTF-8"
              className="product-form product-form-product-template product-form--payment-button-no-variants"
              enctype="multipart/form-data"
              novalidate="novalidate"
              data-product-form=""
            >
              <input type="hidden" name="form_type" value="product" />
              <input type="hidden" name="utf8" value="✓" />

              <select
                name="id"
                id="ProductSelect-product-template"
                className="product-form__variants no-js"
              >
                <option selected="selected" value="36931160658">
                  Default Title
                </option>
              </select>

              <div className="product-form__item product-form__item--quantity">
                <label htmlFor="Quantity-product-template">Quantity</label>
                <input
                  type="number"
                  id="Quantity-product-template"
                  name="quantity"
                  min="1"
                  className="product-form__input"
                  pattern="[0-9]*"
                  data-quantity-input
                  defaultValue="1"
                />
              </div>

              <div
                className="product-form__error-message-wrapper product-form__error-message-wrapper--hidden product-form__error-message-wrapper--has-payment-button"
                data-error-message-wrapper
                role="alert"
              >
                <span className="visually-hidden">Error </span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="presentation"
                  className="icon icon-error"
                  viewBox="0 0 14 14"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path d="M7 0a7 7 0 0 1 7 7 7 7 0 1 1-7-7z" />
                    <path
                      className="icon-error__symbol"
                      d="M6.328 8.396l-.252-5.4h1.836l-.24 5.4H6.328zM6.04 10.16c0-.528.432-.972.96-.972s.972.444.972.972c0 .516-.444.96-.972.96a.97.97 0 0 1-.96-.96z"
                      fill-rule="nonzero"
                    />
                  </g>
                </svg>
                <span
                  className="product-form__error-message"
                  data-error-message
                >
                  Quantity must be 1 or more
                </span>
              </div>

              <div className="product-form__item product-form__item--submit product-form__item--payment-button product-form__item--no-variants">
                {/* Add to cart */}
                <button
                  type="button"
                  name="add"
                  aria-label="Add to cart"
                  className="btn product-form__cart-submit btn--primary-accent"
                  style={{ backgroundColor: "#eeb644", color: "white" }}
                  onClick={handleAddToCartClick}
                >
                  <span>Add to cart</span>
                  <span className="hide">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      className="icon icon-spinner"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M7.229 1.173a9.25 9.25 0 1 0 11.655 11.412 1.25 1.25 0 1 0-2.4-.698 6.75 6.75 0 1 1-8.506-8.329 1.25 1.25 0 1 0-.75-2.385z"
                        fill="#919EAB"
                      />
                    </svg>
                  </span>
                </button>

                {/* payment-button */}
                {/* <div data-shopify="payment-button" data-has-selling-plan="false" data-has-fixed-selling-plan="false"
                      className="shopify-payment-button">
                        <button
                        className="shopify-payment-button__button shopify-payment-button__button--unbranded  shopify-payment-button__button--hidden"
                        disabled="disabled" aria-hidden="true"> 
                        </button>
                      <button
                        className="shopify-payment-button__more-options shopify-payment-button__button--hidden"
                        disabled="disabled" aria-hidden="true">
                       </button></div> */}
              </div>
            </form>
          </div>
          <p
            className="visually-hidden"
            data-product-status
            aria-live="polite"
            role="status"
          ></p>
          <p
            className="visually-hidden"
            data-loader-status
            aria-live="assertive"
            role="alert"
            aria-hidden="true"
          >
            Adding product to your cart
          </p>

          <div className="product-single__description rte">
            <meta charset="utf-8" />
            <meta charset="utf-8" />
            <ul>
              <li>Free Worldwide shipping.</li>
              <li>100-day returns.</li>
              <li>Crafted in Europe.</li>
            </ul>
            <p>
              <span style={{ fontWeight: "400" }}>
                <br />
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="grid__item product-single__photos medium-up--one-half">
        <br />
        <br />
        <h2>
          <span style={{ fontWeight: "400" }}>Description</span>
        </h2>
        <p>
          <span style={{ fontWeight: "400", test: "left" }}>
            {productDescription}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ProductDetails;

import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Slider from "react-slick";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Style from "../assets/css/app.min.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useProductStorage } from "../hooks/useLocalStorage";

import productsData from "../Data/products.json";

import FullScreanProduct from "../components/FullScreanProduct";

import Modal from "react-modal"; // Import the Modal component

const ProductDetailsPage = () => {
  //************* New **************************** */
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const { addProduct } = useProductStorage();


  //new Local Storage
  const initialProductData = [];
  // Initialize state to store the product data
  const [productData, setProduct] = useState(initialProductData);

  const productName = productData.productName;
  const price = productData.salePrice;
  const description = productData.description;
  const description2 = productData.description2;
  const description3 = productData.description3;
  const imagesUrl = [
    require("../assets/images/products/1S_530x@2x2bb5.jpg"),
    require("../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg"),
    require("../assets/images/products/1S_530x@2x2bb5.jpg"),
  ];
  // const productDescription = productData.productDescription;

  const handleAddToCartClick = () => {
    const quantityInput = document.getElementById("quantity");
    const quantity = parseInt(quantityInput.value);

    // Pass the product and quantity to the addProduct function
    addProduct(productData, quantity);
    
    window.location.reload();


  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  //*********** Laste *********** */
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Sample product images
  const productImages = [
    "../assets/images/products/Flockmen_012017_-_01c_1200x1200de45.jpg",
    "../assets/images/products/1.FlockmenSKINTONESset_smphoto.lv_1024x1024@2x1d7c.jpg",
    "../assets/images/products/Flockmen_122016_-_05_1200x1200dcee.jpg",
    "../assets/images/products/Flockmen_122016_-_11c_ad92752c-e5b5-4222-b489-0a2aff2af8d5_1200x1200e460.jpg",
    // Add more image paths as needed
  ];

  const productImages2 = [
    "../assets/images/products/starmen.png",
    "../assets/images/products/starmen2.png",
    "../assets/images/products/starmen3.png",
    "../assets/images/products/starmen4.png",
    "../assets/images/products/starmen5.png",
    // Add more image paths as needed
  ];

  //*********** New ****************** */
  useEffect(() => {
    const productById = productsData.find(
      (product) => product.id === parseInt(id)
    );

    if (productById) {
      setProduct(productById);
    }
  }, [id]);

  if (!productData) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Header />
      {/* Start Page Content Wrapper */}
        <div className="product-details-page-content">
          <div className="container container-wide">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {/* Start Product Thumbnail Area */}
                  <div className="col-md-5">
                    <FullScreanProduct productName={productName} />
                  </div>
                  {/* End Product Thumbnail Area */}

                  {/* Start Product Info Area */}
                  <div className="col-md-7">
                    <div className="product-details-info-content-wrap">
                      <div className="prod-details-info-content">
                        <h1>{productName}</h1>
                        <br />
                        <h1 className="price">
                          <strong>{price} DT</strong>
                        </h1>
                        <p>{description}</p>
                        <p>{description2}</p>
                        <div className="product-config">
                          <div className="table-responsive">
                            {/* <table className="table table-bordered">
                              <tr>
                                <th className="config-label">Color</th>
                                <td className="config-option">
                                  <div className="config-color">
                                    <a href="#">Black</a> <a href="#">Blue</a>
                                    <a href="#">Green</a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th className="config-label">Size</th>
                                <td className="config-option">
                                  <div className="config-color">
                                    <a href="#">Large</a> <a href="#">Medium</a>
                                    <a href="#">Small</a>
                                  </div>
                                </td>
                              </tr>
                            </table> */}
                          </div>
                        </div>
                        {/* Begin Add cart button */}
                        <div className="product-action">
                          <div className="action-top d-sm-flex">
                            <div className="pro-qty mr-3 mb-4 mb-sm-0">
                              <label htmlFor="quantity" className="sr-only">
                                Quantity
                              </label>
                              <a href="#" class="inc qty-btn" onClick={handleIncrement}>
                                +
                              </a>
                              <input
                                type="number"
                                id="quantity"
                                title="Quantity"
                                value={quantity}
                                min="1"
                                onChange={(e) => setQuantity(e.target.value)}
                              />
                              <a href="#" class="dec qty-btn" onClick={handleDecrement}>
                                -
                              </a>
                            </div>
                            <button
                              className="btn btn-bordered"
                              style={{
                                backgroundColor: "#eeb644",
                                color: "white",
                              }}
                              onClick={handleAddToCartClick}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                        {/* END Add cart button */}
                        {/* <div className="product-meta">
                          <span className="sku_wrapper">
                            SKU: <span className="sku">N/A</span>
                          </span>
                          <span className="posted_in">
                            Categories: <a href="#">Best Seller,</a>
                            <a href="#">Parts,</a> <a href="#">Shop</a>{" "}
                          </span>
                          <span className="tagged_as">
                            Tags: <a href="#">Seller,</a>
                            <a href="#">Modern,</a> <a href="#">Parts</a>
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* End Product Info Area */}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="product-description-review">
                      {/* Product Description Tab Menu */}
                      <ul
                        className="nav nav-tabs desc-review-tab-menu"
                        id="desc-review-tab"
                        role="tablist"
                      >
                        <li>
                          <a
                            // className="active"
                            id="desc-tab"
                            data-toggle="tab"
                            href="#descriptionContent"
                            role="tab"
                            style={{
                              backgroundColor: "#DFDFDF",
                              color: "black",
                            }}
                          >
                            Description
                          </a>
                        </li>
                      </ul>
                      {/* Product Description Tab Content */}
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="descriptionContent"
                        >
                          <div className="description-content">
                            <p>{description}</p>
                            <p>{description2}</p>
                            <p>{description3}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* End Page Content Wrapper */}

      <Footer />
    </>
  );
};

export default ProductDetailsPage;

import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import image1 from "../assets/images/products/28S_1024x1024@2x536f.jpg";
import image2 from "../assets/images/products/1S_530x@2x2bb5.jpg";
import image3 from "../assets/images/products/untitled-04514-2_530x@2x4b2a.jpg";
import image4 from "../assets/images/products/1.ColorFL_1024x1024@2x4dbd.jpg"
import image5 from "../assets/images/products/flockmen_family_l.hercenbergs-3278_1200x1200992f.jpg"
import image6 from "../assets/images/products/starmen4.png"
import image7 from "../assets/images/products/Frame_10.jpg"
import image8 from "../assets/images/products/2S_1024x1024@2x2bb5.jpg"
import image9 from "../assets/images/products/Flockmen_numbers_L.Hercenbergs-2_edd_1200x1200e450.jpg"

const Gallery = () => {
  // Define a state variable to store the list of images
  const imageList = [image1, image2, image3,image4,image5,image6,image7,image8,image9,image1, image2, image3,image4];

  return (
    <>
      <Header />
      <header className="collection-header">
        <div className="page-width">
          <div className="section-header text-center">
            <h1>
              <span role="text">
                <span className="visually-hidden">Collection: </span>
                Gallery
              </span>
            </h1>
          </div>
        </div>
      </header>
      <div className="page-content-wrapper sp-y">
        <div className="gallery-page-content">
          <div className="container container-wide">
            <div className="row mtn-30 image-gallery">
              {/* Map through the imageList state to display each image */}
              {imageList.map((imagePath, index) => (
                <div className="col-sm-6 col-lg-3" key={index}>
                  <div className="gallery-item">
                    {/* Use the image path directly without require */}
                    <img src={imagePath} alt={`gallery-${index}`} />
                    <div className="gallery-item__text">
                      <h3>Produit : {index +1}</h3>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
